import axios from 'axios'
import { Auth } from '@/services/Auth'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (typeof error.response === 'undefined') {
      console.warn(
        'A network error occurred. ' +
          'This could be a CORS issue or a dropped internet connection. ' +
          'It is not possible for us to know. Error:',
        error,
      )
      console.warn('You will now be logged out.')

      delete api.defaults.headers.common.Authorization
      localStorage.removeItem(Auth.TOKEN_KEY)
      localStorage.removeItem(Auth.REFRESH_TOKEN_KEY)

      this.router.push({ name: 'Login' }).catch((error) => {
        console.error('Error during redirection:', error)
      })
    }

    return Promise.reject(error)
  },
)

export default api
