import { Auth } from '@/services/Auth'
import { getIdentityFromJwt } from '@/utils/helpers'

export class User {
  identity = {}
}

export class Identity {
  userId
  token
  refreshToken
  expires
  roles

  constructor(userId, token, refreshToken, expires, roles) {
    this.userId = userId
    this.token = token
    this.refreshToken = refreshToken
    this.expires = expires
    this.roles = roles
  }
}

export class UserModule {
  namespaced = true
  state

  mutations = {
    login(state, identity) {
      state.identity = identity
    },
    logout(state) {
      state.identity = {}
    },
  }

  constructor() {
    const user = new User()
    const token = localStorage.getItem(Auth.TOKEN_KEY)

    if (token) {
      user.identity = getIdentityFromJwt(token)
    }

    this.state = user
  }
}
