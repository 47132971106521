<template>
  <CToaster placement="bottom-end">
    <div v-for="(toast, index) in toasts" v-bind:key="index">
      <CToast v-bind:color="toast.type">
        <div class="d-flex">
          <CToastBody>
            {{ toast.content }}
          </CToastBody>
          <CToastClose class="me-2 m-auto" />
        </div>
      </CToast>
    </div>
  </CToaster>
</template>
<script>
export default {
  name: 'Toast',
  data() {
    return {
      // types: 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light'
      toasts: [],
    }
  },
  methods: {
    add(content, type) {
      this.toasts.push({
        type: type,
        content: content,
      })
    },
  },
}
</script>
