export default [
  {
    component: 'CNavItem',
    name: 'Zamówienia',
    to: '/orders',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Oddziały',
    to: '/branches',
    icon: 'cil-star',
  },
]
